import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';

import classes from './NavigationItems.module.css';

const NavigationItems: React.FC<{}> = () => {
  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/">Home</NavigationItem>
      <NavigationItem link="/portfolio">Portfolio</NavigationItem>
      <NavigationItem link="/resume">Resume</NavigationItem>
    </ul>
  );
};

export default NavigationItems;
