import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

interface NavigationItemProps {
  link: string;
  children: React.ReactNode;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ link, children }) => {
  return (
    <li className={classes.NavigationItem}>
      <NavLink to={link} exact activeClassName={classes.active}>
        {children}
      </NavLink>
    </li>
  );
};

export default NavigationItem;
