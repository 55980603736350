import React from 'react';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

import classes from './Layout.module.css';

interface iLayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<iLayoutProps> = ({ children }) => {
  const [showSideDrawer, setShowSideDrawer] = React.useState(false);

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
  };

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer((prevShowSideDrawer) => !prevShowSideDrawer);
  };

  return (
    <div className={classes.Body}>
      <Toolbar drawerToggleClicked={sideDrawerToggleHandler} />
      <SideDrawer open={showSideDrawer} closed={sideDrawerClosedHandler} />
      <main className={classes.Content}>{children}</main>
    </div>
  );
};

export default Layout;
