import React from 'react';
import classes from './SectionTitle.module.css';

interface SectionTitleProps {
  children: React.ReactNode;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
  return <div className={classes.SectionTitle}>{children}</div>;
};

export default SectionTitle;
