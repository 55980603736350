import React, { useState, createContext, useEffect } from 'react';
import Layout from './hoc/Layout/Layout';
import { Route, Switch } from 'react-router-dom';

import Home from './containers/Home/HomePage';
import Portfolio from './containers/Portfolio/PortfolioPage';
import Resume from './containers/Resume/ResumePage';
import MFGPrivacyPolicy from './containers/MFG/MFGPrivacyPolicy';

import 'bootstrap/dist/css/bootstrap.min.css';
import MFGLandingPage from './containers/MFG/MFGLandingPage';

interface iThemeContext {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}
export const ThemeContext = createContext<iThemeContext>({ darkMode: false, setDarkMode: () => {} });

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const darkModeTheme = {
    darkMode,
    setDarkMode,
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('body-dark');
    } else {
      document.body.classList.remove('body-dark');
    }
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={darkModeTheme}>
      <Layout>
        <Switch>
          <Route path="/resume" component={Resume} />
          <Route path="/portfolio" component={Portfolio} />
          <Route exact path="/my-friend-gym" component={MFGLandingPage} />
          {/* https://stackoverflow.com/questions/45785583/react-router-dom-routes-and-sub-routes */}
          <Route path="/my-friend-gym/privacy-policy" component={MFGPrivacyPolicy} />
          <Route path="/" exact component={Home} />
        </Switch>
      </Layout>
    </ThemeContext.Provider>
  );
}

export default App;
