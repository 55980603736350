import React from 'react';

import classes from './PortfolioItem.module.css';

interface PortfolioItemProps {
  greyscale: boolean;
  itemLink: string;
  imgLink: string;
  title: string;
  description: string;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({ greyscale, itemLink, imgLink, title, description }) => {
  const imageClass = greyscale ? classes.greyscaleImage : '';

  const ImageLink =
    itemLink !== '' ? (
      <a href={itemLink} target="_blank" rel="noopener noreferrer">
        <img className={imageClass} src={imgLink} alt={`ImageLink`} />
      </a>
    ) : (
      <img className={imageClass} src={imgLink} alt={`ImageLink`} />
    );

  const ItemTitle =
    itemLink !== '' ? (
      <div className={classes.PortfolioTitle}>
        <a href={itemLink} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </div>
    ) : (
      <div className={classes.PortfolioTitle}>{title}</div>
    );

  return (
    <div className={classes.PortfolioItem}>
      <div className={classes.TitleContainer}>
        {ItemTitle}
        <div className={classes.PortfolioImageLink}>{ImageLink}</div>
      </div>
      <div className={classes.PortfolioDescription}>{description}</div>
    </div>
  );
};

export default PortfolioItem;
