import React from 'react';

import Professions from './utils/Professions';

import Section from '../utils/Section/Section';

import classes from './ProfessionalHistory.module.css';
interface ProfessionProps {
  company: string;
  role: string;
  jobDescription: string;
}
const Profession: React.FC<ProfessionProps> = ({ company, role, jobDescription }) => {
  return (
    <div className={classes.Profession}>
      <div className={classes.CompanyTitle}>{company}</div>
      <div className={classes.Role}>{role}</div>
      <div className={classes.JobDescription} dangerouslySetInnerHTML={{ __html: jobDescription }} />
    </div>
  );
};

const ProfessionalHistory: React.FC<{}> = () => {
  const professions = Professions.map((profession) => <Profession key={profession.company} {...profession} />);

  return (
    <Section title="Professional History" bodyClassName={classes.professionalHistoryBody}>
      {professions}
    </Section>
  );
};

export default ProfessionalHistory;
