import React from 'react';

import PortfolioItems from './PortfolioHelpers/PortfolioItems/PortfolioItems';

import classes from './Portfolio.module.css';

const Portfolio: React.FC<{}> = () => {
  return (
    <div className={classes.Portfolio}>
      <PortfolioItems />
    </div>
  );
};

export default Portfolio;
