import React from 'react';

import Accomplishments from './utils/Accomplishments';
import classes from './OtherAccomplishments.module.css';

import SectionTitle from '../ResumeHelpers/SectionTitle/SectionTitle';
import SectionBody from '../ResumeHelpers/SectionBody/SectionBody';

interface AccomplishmentProps {
  content: string;
}

const Accomplishment: React.FC<AccomplishmentProps> = ({ content }) => {
  return (
    <li className={classes.Accomplishment}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </li>
  );
};

interface OtherAccomplishmentsProps {}

const OtherAccomplishments: React.FC<OtherAccomplishmentsProps> = () => {
  const accomplishments = Accomplishments.map((accomplishment) => (
    <Accomplishment key={accomplishment} content={accomplishment} />
  ));

  return (
    <div className={classes.OtherAccomplishments}>
      <SectionTitle>Side Projects</SectionTitle>
      <SectionBody>
        <ul>{accomplishments}</ul>
      </SectionBody>
    </div>
  );
};

export default OtherAccomplishments;
