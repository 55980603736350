import React from 'react';

import classes from './SectionBody.module.css';

interface SectionBodyProps {
  children: React.ReactNode;
  isOpen?: boolean;
  bodyClassName?: string;
}

const SectionBody: React.FC<SectionBodyProps> = ({ children, isOpen = true, bodyClassName }) => {
  const openClass = isOpen ? classes.open : classes.closed;
  const cssClasses = [classes.SectionBody, openClass, bodyClassName ?? ''].join(' ');

  return <div className={cssClasses}>{children}</div>;
};

export default SectionBody;
