import MFGIcon from '../Portfolio/PortfolioHelpers/PortfolioItems/utils/MFG.png';
import AppBadge from './utils/AppBadge';

import classes from './MFGLP.module.css';

import iOSAppStore from './utils/Download_on_the_App_Store_Badge_US-UK_RGB_blk.svg';
import googlePlayStore from './utils/google-play-badge.png';

const MFGLP = () => {
  return (
    <div
      className={classes.MFGLP}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <img
          src={MFGIcon}
          style={{ height: 120, width: 120, borderRadius: '8px', boxShadow: '0 2px 4px', margin: 12 }}
          alt="My Friend Gym Logo"
        />
        <h1>My Friend Gym</h1>
      </div>
      <div style={{ margin: 18, paddingTop: 24, paddingBottom: 24 }}>
        <div>
          My Friend Gym is an app that can quickly convert your pounds to kilos, and show you which plates to put on
          your bar! It can also estimate your one rep max and calculate your wilks score.
        </div>
        <div style={{ padding: 12 }}> Thanks for checking out My Friend Gym!</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingTop: '36px',
        }}
      >
        <div style={{ padding: 12 }}>
          <AppBadge urlLocation="https://appstore.com/brucerandall/myfriendgym" imageSource={iOSAppStore} />
        </div>
        <div style={{ padding: 12 }}>
          <AppBadge
            urlLocation="https://play.google.com/store/apps/details?id=dev.brucerandall.myfriendgym"
            imageSource={googlePlayStore}
          />
        </div>
      </div>
    </div>
  );
};

export default MFGLP;
