import React from 'react';

import Backdrop from '../../UI/Backdrop/Backdrop';
import NavigationItems from '../NavigationItems/NavigationItems';

import classes from './SideDrawer.module.css';

interface SideDrawerProps {
  open: boolean;
  closed: () => void;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ open, closed }) => {
  const sideDrawerClasses = classes.SideDrawer;

  let attachedClasses = [sideDrawerClasses];
  attachedClasses.push(open ? classes.Open : classes.Close);

  return (
    <>
      <Backdrop show={open} clicked={closed} />
      <div className={attachedClasses.join(' ')}>
        <nav>
          <NavigationItems />
        </nav>
      </div>
    </>
  );
};

export default SideDrawer;
