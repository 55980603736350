import React from 'react';
import classes from './PortfolioItems.module.css';
import PortfolioItem from './PortfolioItem/PortfolioItem';

import { portfolioEntries } from './utils/portfolioIEntries';

const PortfolioItems: React.FC<{}> = () => {
  const portfolioItems = portfolioEntries.map((portfolioEntry) => {
    return <PortfolioItem key={portfolioEntry.title} {...portfolioEntry} />;
  });

  return (
    <div className={classes.PortfolioItems}>
      <div className={classes.PortfolioTitle}>Below is my portfolio</div>
      <div className={classes.PortfolioDescription}>
        This will be a living document of software I develop professionally and side projects!
      </div>
      {portfolioItems}
    </div>
  );
};

export default PortfolioItems;
