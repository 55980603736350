import React from 'react';

import NavigationItems from '../../Navigation/NavigationItems/NavigationItems';
import DarkModeSwitch from '../../UI/DarkModeSwitch/DarkModeSwitch';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

import classes from './Toolbar.module.css';

interface ToolbarProps {
  drawerToggleClicked: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ drawerToggleClicked }) => {
  return (
    <div className={classes.Toolbar}>
      <DrawerToggle clicked={drawerToggleClicked} />
      <div className={classes.Logo}>{/* <Logo /> */}</div>
      <div className={classes.DarkModeSwitchContainer}>
        <DarkModeSwitch platform="" />
      </div>

      <nav className={classes.DesktopOnly}>
        <NavigationItems />
      </nav>
    </div>
  );
};

export default Toolbar;
