import React from 'react';

import classes from './Summary.module.css';

import SectionTitle from '../ResumeHelpers/SectionTitle/SectionTitle';
import SectionBody from '../ResumeHelpers/SectionBody/SectionBody';

const Summary: React.FC<{}> = () => {
  return (
    <div className={classes.Summary}>
      <SectionTitle>Summary</SectionTitle>
      <SectionBody>
        Experienced software developer with over 7.5 years of experience in the gaming and sports betting industries.
        Proficient in C++ and Javascript for creating robust and scalable applications. Proven track record of
        delivering innovative and user-friendly software solutions to meet business needs. Adept in Cordova and React
        Native for developing mobile applications. Strong ability to work collaboratively in cross-functional teams and
        a passion for staying up-to-date with the latest industry developments.
      </SectionBody>
    </div>
  );
};

export default Summary;
