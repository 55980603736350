const Accomplishments = [
  `
  Using Bare Expo workflow - React Native to build <a href='/my-friend-gym' rel='noopener noreferrer'>My Friend Gym</a>.</br>
  <ul>
    <li>Integrated <b>Google Sign-In</b>, <b>Firebase Authentication</b>, and <b>Firebase</b> Realtime Database.</li>
    <li>Built custom graph component using <b>react-native-svg</b>.</li>
  </ul>
  `,
  `Be sure to check out my past and current projects on my
  <a href='/portfolio' rel="noopener noreferrer">
    Portfolio
  </a> page!`,
];

export default Accomplishments;
