import classes from '../UsefulProficiencies.module.css';

const languages = ['C++', 'TypeScript', 'JavaScript', 'Python', 'HTML', 'CSS', 'Swift'];
const frameworks = ['React', 'Next.js', 'Cordova', 'Node.js', 'React Native', 'Unity', 'Unreal'];
const versionControl = ['Git', 'SVN', 'Github', 'GitLab'];
const googleServices = ['Google Analytics', 'Google Tag Manager', 'Google Firebase Auth/Firestore'];
const misc = ['Mac', 'Windows', 'Linux', 'Atlassian Products', 'Microsoft Office'];

const shortListStyle = [classes.list].join(' ');
const longListStyle = [classes.list, classes.longList].join(' ');

const ProficiencyData = [
  { list: languages, title: 'Languages', style: longListStyle },
  { list: frameworks, title: 'Frameworks/Engines', style: longListStyle },
  { list: versionControl, title: 'Version Control', style: longListStyle },
  { list: googleServices, title: 'Google Services', style: shortListStyle },
  { list: misc, title: 'Misc', style: longListStyle },
];

export default ProficiencyData;
