import React from 'react';
import classes from './Education.module.css';

import Section from '../utils/Section/Section';

const Education: React.FC<{}> = () => {
  const educationBody = (
    <>
      <div className={classes.SchoolName}>University of Nevada Las Vegas Graduation Date: December 2015</div>
      <div className={classes.Degree}>Bachelor of Science in Computer Science</div>
      <div className={classes.SchoolName}>The Pennsylvania State University Graduation Date: December 2010</div>
      <div className={classes.Degree}>Bachelor of Science in Physics</div>
      <div className={classes.Degree}>Minor in Mathematics</div>
    </>
  );

  return (
    <Section title="Education" bodyClassName={classes.EducationBody}>
      {educationBody}
    </Section>
  );
};

export default Education;
