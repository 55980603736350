import React from 'react';

import DownArrow from '../../../../../common/DownArrow/DownArrow';
import UpArrow from '../../../../../common/UpArrow/UpArrow';

import SectionTitle from '../../ResumeHelpers/SectionTitle/SectionTitle';
import classes from './SectionHeader.module.css';

interface iSectionContainerProps {
  title: string;
  isOpen: boolean;
  onArrowClick: () => void;
}
const SectionHeader: React.FC<iSectionContainerProps> = ({ title, isOpen, onArrowClick }) => {
  const arrowIcon = isOpen ? (
    <UpArrow classname={'container-up-arrow'} />
  ) : (
    <DownArrow classname={'container-down-arrow'} />
  );

  return (
    <div className={classes.SectionHeader} onClick={onArrowClick}>
      <SectionTitle>{title}</SectionTitle>
      <div>{arrowIcon}</div>
    </div>
  );
};

export default SectionHeader;
