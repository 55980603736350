import React, { useState } from 'react';

import SectionContainer from '../SectionContainer/SectionContainer';
import SectionHeader from '../SectionHeader/SectionHeader';
import SectionBody from '../../ResumeHelpers/SectionBody/SectionBody';

interface iSectionProps {
  title: string;
  children: React.ReactNode;
  bodyClassName?: string;
}

const Section: React.FC<iSectionProps> = ({ title, children, bodyClassName }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleArrowClick = () => {
    setIsOpen((currentisOpen) => !currentisOpen);
  };

  return (
    <SectionContainer>
      <SectionHeader onArrowClick={handleArrowClick} isOpen={isOpen} title={title} />
      <SectionBody isOpen={isOpen} bodyClassName={bodyClassName ?? ''}>
        {children}
      </SectionBody>
    </SectionContainer>
  );
};

export default Section;
