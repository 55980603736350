import React from 'react';
import Name from '../ResumeHelpers/Name/Name';
import Header from '../ResumeHelpers/Header/Header';

import MyImage from './me.jpg';
import classes from './Heading.module.css';

interface HeadingProps {}

const Heading: React.FC<HeadingProps> = () => {
  return (
    <div className={classes.Heading}>
      <div className={classes.Description}>
        <Name>Bruce Randall</Name>
        <Header>Software Developer - C++/Javascript</Header>
      </div>
      <div className={classes.ImageContainer}>
        <img className={classes.MyImage} src={MyImage} alt="MyPicture" />
      </div>
    </div>
  );
};

export default Heading;
