import React from 'react';

import Heading from './ResumeComponents/Heading/Heading';
import Summary from './ResumeComponents/Summary/Summary';
import ProfessionalHistory from './ResumeComponents/ProfessionalHistory/ProfessionalHistory';
import Education from './ResumeComponents/Education/Education';
import UsefulProficiencies from './ResumeComponents/UsefulProficiencies/UsefulProficiencies';
import OtherAccomplishments from './ResumeComponents/OtherAccomplishments/OtherAccomplishments';

import classes from './Resume.module.css';

const Resume: React.FC<{}> = () => {
  return (
    <div className={classes.Resume}>
      <Heading />
      <Summary />
      <ProfessionalHistory />
      <Education />
      <UsefulProficiencies />
      <OtherAccomplishments />
    </div>
  );
};

export default Resume;
