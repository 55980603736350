import React from 'react';

import classes from './SectionContainer.module.css';

interface iSectionContainerProps {
  children: React.ReactNode;
}

const SectionContainer: React.FC<iSectionContainerProps> = ({ children }) => {
  return (
    <div className={classes.SectionContainer}>
      <div className={classes.Section}>{children}</div>
    </div>
  );
};

export default SectionContainer;
