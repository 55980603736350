import React from 'react';

import classes from './Header.module.css';

interface HeaderProps {
  children: React.ReactNode;
}

let Header: React.FC<HeaderProps> = ({ children }) => {
  return <div className={classes.Header}>{children}</div>;
};

export default Header;
