import MFGIcon from './MFG.png';
import KnightsArsenalIcon from './KnightsArsenal.png';

export const portfolioEntries = [
  {
    title: `My Friend Gym: iOS/Android - React Native`,
    itemLink: `https://www.brucerandall.me/my-friend-gym`,
    imgLink: MFGIcon,
    greyscale: false,
    description: `My Friend Gym has been revived!
    The app has been fully rewritten using React Native and Typescript. The expo build pipeline is an integral piece to the speed of iteration.
    My Friend Gym is currently being worked on and updated frequently. In MFGv2.2 I introduced Firebase and Google Authentication while also building a custom graph component using react-native-svg.  If it doesn't have a feature you want, please let me know.
    The dream would be to grow this into a toolkit for everyone's gym goals!`,
  },
  {
    title: `Game of Thrones: King's Landing - C++`,
    itemLink: `https://www.aristocratgaming.com/us/slots/games/game-of-thrones-kings-landing`,
    imgLink: `https://www.aristocratgaming.com/_product-assets/games-assets/game-of-thrones-kings-landing/kings-landing_gt_game-card-front_202104_f.jpg`,
    greyscale: false,
    description: `For this game, I programmed the Red Keep Bonus feature.  The game was unique because of its tiered payout system based on geometry. 
    As the dragon breathed fire over your symbols, they would unlock or double depending on the amount of times the dragon chose to breathe fire on that symbol.
    I had to build a special payout calculator specifically for this feature and worked closely with the Technical Artist to ensure the asset overhead was minimized.
    `,
  },
  {
    title: `Star Trek: TNG - C++`,
    itemLink: 'https://www.aristocratgaming.com/us/slots/games/star-trek-the-next-generation',
    imgLink: `https://www.aristocratgaming.com/_product-assets/games-assets/star-trek-tng/star-trek_gt_game-card-front_202011_f.jpg`,
    greyscale: false,
    description: `Star Trek was the flagship game for Aristocrat at G2E 2019.

        My role involved creating the Encounter Free Games feature and all auxiliary portions of the game, edge lights, attract animations, button deck easter eggs! This game was also the first game that Mojo studio provided space for myself and a peer Engineer to work on without the supervision of a Senior Engineer.`,
  },
  {
    title: `Bartop: Winner's World - C++`,
    itemLink: 'https://www.aristocrat-us.com/winners-world',
    imgLink: `https://www.freearistocratpokies.com/wp-content/uploads/Winners-World.jpg`,
    greyscale: false,
    description: `Winner's World was the first game of it's kind from Aristocrat. This meant that while we were building the game, the engine team was also building the engine. Of course that provided some opportune moments to provide feedback to the engine team. It also came with challenges like being blocked by a part of the engine that was not written yet. I also helped the engine team track down a memory leak that was going to cause field issues!`,
  },
  {
    title: `WestWorld - C++`,
    itemLink: 'https://www.aristocrat-us.com/westworld-1',
    imgLink: `https://soloazar.s3.amazonaws.com/images/9424_crop169004_1024x576_98L66wdfdU1RapajWqANKId4dDvb8rrFXCc.png`,
    greyscale: false,
    description: `WestWorld was a really fun game to build. The two free games features were my main responsibility, Mariposa and the Lab. Because of the creativeness of these features, I was able to learn and get significant experience during the game.
        For Mariposa Free Games, I built a helper object that would handle half of the game's state, while the slot game was played underneath.  The Lab's Free Games feature involved keeping track of three slot games at the same time and comparing states between them to determine if the player would be awarded a win!`,
  },
  {
    title: `My Friend Gym: iOS - Swift (inactive)`,
    itemLink: '',
    imgLink: MFGIcon,
    greyscale: true,
    description: `My Friend Gym is an app that can quickly convert your pounds to kilos, and show you which plates to put on your bar!  It can also estimate your one rep max and calculate your wilks score. 

        All the bars and the plates were programmatically drawn using XCode and Swift. The math heavy algorithms were fun to convert to code, I created a specific math object in order to drive all the calculations and handle state. 
        This was also my first experience with using iOS databases memory.`,
  },
  {
    title: `Knight's Arsenal: iOS - Swift (inactive)`,
    itemLink: '',
    imgLink: KnightsArsenalIcon,
    greyscale: true,
    description: `Instead of carrying around a bag of dice, counters, notebook and a pen, all we needed for Dungeon's and Dragons was Knight's Arsenal. A player could access their photos to set their character's avatar, I took advantage of list views to make sure the character's inventory was properly scalable. Tabbed views allowed me to add some counter functionality and simulated dice rolling!`,
  },
];
