import React from 'react';

import classes from './Backdrop.module.css';

interface iBackdropProps {
  show: boolean;
  clicked: () => void;
}

const Backdrop: React.FC<iBackdropProps> = ({ show, clicked }) => {
  return show ? <div className={classes.Backdrop} onClick={clicked}></div> : null;
};

export default Backdrop;
