import React from 'react';

import Home from '../../components/Home/Home';

const HomePage: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  );
};

export default HomePage;
