import React, { useContext } from 'react';
import { IonIcon } from '@ionic/react';
import { sunnyOutline, moonOutline } from 'ionicons/icons';

import { ThemeContext } from '../../../App';

import classes from './DarkModeSwitch.module.css';

interface DarkModeSwitchProps {
  platform: string;
}

const DarkModeSwitch: React.FC<DarkModeSwitchProps> = ({ platform }) => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const onSwitchAction = () => {
    setDarkMode(!darkMode);
  };

  const containerCSS = platform === 'Desktop' && classes.DesktopOnly;
  const switchIcon = darkMode ? sunnyOutline : moonOutline;

  return (
    <div className={[classes.DarkModeContainer, containerCSS].join(' ')}>
      <IonIcon
        onClick={onSwitchAction}
        style={{ height: 32, width: 32 }}
        className={classes.DarkModeLabel}
        icon={switchIcon}
      />
    </div>
  );
};

export default DarkModeSwitch;
