interface AppBadgeProps {
  imageSource: string;
  urlLocation: string;
}

const AppBadge = ({ imageSource, urlLocation }: AppBadgeProps) => {
  const isGooglePlayLink = urlLocation.includes('play.google.com');
  return (
    <a href={urlLocation} target="_blank" rel="noreferrer">
      <img style={{ height: isGooglePlayLink ? 115 : 80 }} src={imageSource} alt={`Download here: ${urlLocation}`} />
    </a>
  );
};

export default AppBadge;
