const Professions = [
  {
    company: "Caesars' Digital: Las Vegas, NV July 2021 - Present",
    role: 'Software Engineer',
    jobDescription: `Built flow from user acquisition link, through first time registration
     and deposit of Caesars' Sportsbook App using <b>React.js</b>, <b>Next.js</b>, <b>Cordova</b>, and <b>Redux</b>.
    <br />
    Supported marketing requests utilizing <b>Content Stack</b> as a headless CMS and <b>Google Tag Manager</b> for user tracking.`,
  },
  {
    company: 'Aristocrat Technologies Incorporated: Las Vegas, NV March 2016 - July 2021',
    role: 'Software Engineer',
    jobDescription: `Used proprietary game engine to build slot games in C++:
          <ul className={classes.GamesList}>
            <li>The Big Lebowski</li>
            <li>WestWorld</li>
            <li>Buffalo Gold Cinema</li>
            <li>Star Trek</li>
            <li>Winner’s World Game Pack</li>
            <li>Game of Thrones: King's Landing</li>
          </ul>`,
  },
  {
    company: '1337PCs.com: Las Vegas, NV August 2018 - March 2021',
    role: 'Business Co-Owner',
    jobDescription: `Pivoted business from brick and mortar LAN center to online custom PC retailer when quarantine began - profits
      rose by 300%
      <br />
      Applied DevOps principles using <b>Node.js</b> servers to utilize <b>AWS</b>, <b>Slack</b>, <b>Shopify</b> and <b>Trello</b> APIs, to reduce lead
      time by 50%`,
  },
  {
    company: 'University of Nevada Las Vegas: Las Vegas, NV Summer 2014 - May 2016',
    role: 'Bioinformatics Software Developer',
    jobDescription: `Upgraded HIVToolkit to version 2.0 and integrated BootStrap CSS to front end
      <br />
      Developed Java server side, and other data processing software as requested`,
  },
];

export default Professions;
