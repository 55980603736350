import React from 'react';

import Section from '../utils/Section/Section';

import ProficiencyData from './utils/ProficiencyData';
import classes from './UsefulProficiencies.module.css';

interface ProficiencyListProps {
  proficiencies: string[];
  listName: string;
  listType: string;
}

const ProficienyList: React.FC<ProficiencyListProps> = ({ proficiencies, listName, listType }) => (
  <>
    <div className={classes.ListDescription}>{listName}:</div>
    <div className={listType}>
      {proficiencies.map((proficiency) => {
        return (
          <li key={proficiency} className={classes.proficiency}>
            {proficiency}
          </li>
        );
      })}
    </div>
  </>
);

let UsefulProficiencies: React.FC<{}> = () => {
  const proficiencies = ProficiencyData.map((proficiency) => {
    return (
      <ProficienyList
        key={proficiency.title}
        proficiencies={proficiency.list}
        listName={proficiency.title}
        listType={proficiency.style}
      />
    );
  });

  return <Section title="Useful Proficiencies">{proficiencies}</Section>;
};

export default UsefulProficiencies;
