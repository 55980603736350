import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Home.module.css';

const Home: React.FC<{}> = () => {
  return (
    <div className={classes.Home}>
      <div className={classes.Welcome}>Welcome!</div>
      <div className={classes.Resume}>
        If you are looking for my resume, you can find it{' '}
        <NavLink to={`/resume`} exact>
          here
        </NavLink>
        .
      </div>
      <div className={classes.Portfolio}>
        If you're looking for a list of past and current projects, you should check out my{' '}
        <NavLink to={`/portfolio`} exact>
          portfolio
        </NavLink>
        !
      </div>
    </div>
  );
};

export default Home;
